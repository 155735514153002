.footer {
    background-color: transparent; /* Set the background to transparent */
    color: #AAAA;
    padding: 20px;
  }
  
  .social-icons {
    display: flex;
    justify-content: flex-end;
  }
  
  .social-icons a {
    color: #fff; /* Icon color */
    font-size: 24px; /* Icon size */
    margin: 0 10px;
    transition: color 0.3s ease; /* Smooth color transition on hover */
  }
  
  .social-icons a:hover {
    color: #ff9900; /* Change color on hover */
  }
  