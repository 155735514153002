.mainCard {
    height: 300px;
    width: 400px;
    display: flex;
    position: relative;
    cursor: pointer;
}
.mainCard:hover{
    transform: scale(1.05);
    transition:ease-in 0.1s;
}

.mainImg {
    height: 100%;
    width: 100%;
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.2);
    filter: saturate(0.7) contrast(1) brightness(1);
    object-fit: cover;
    border-radius: 10px;
}

.imgTitle {
    font-weight: bold;
    position: absolute;
    /* bottom: ; */
    top: 85%;
    color: white;
    right: 0;
    padding-right: 10px;
    font-size: 20px;

    /* right: 0; */
}

@media screen and (max-width: 960px) {
    .mainCard {
        height: 200px;
        width: 300px;
    }

    .imgTitle {
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .checkout_form {
        width: 100%;
    }
}