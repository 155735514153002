.button1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px 10px 12px;
    box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color: #e8e8e8;
    border-color: #E0F2F1;
    border-style: solid;
    border-width: 6px;
    border-radius: 30px;
    font-size: 25px;
    font-weight: 900;
    color: rgb(134, 124, 124);
    font-family: monospace;
    transition: transform 400ms cubic-bezier(.68, -0.55, .27, 2.5),
        border-color 400ms ease-in-out,
        background-color 400ms ease-in-out;
    word-spacing: -2px;
    animation: movingBorders 3s infinite;
    margin-top: 14px;
    display: flex;
    justify-content: center;
}

button:disabled,
button[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    pointer-events:none;
}

@keyframes movingBorders {
    0% {
        border-color: #E0F2F1;
    }

    50% {
        border-color: #90CAF9;
    }

    90% {
        border-color: #64B5F6;
    }
}

.button1:hover {
    background-color: #eee;
    transform: scale(105%);
    animation: movingBorders 3s infinite;
}

.button1 svg {
    margin-right: 11px;
    fill: #64B5F6;
    transition: opacity 100ms ease-in-out;
}



@keyframes beatingHeart {
    0% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.15);
    }

    30% {
        transform: scale(1);
    }

    45% {
        transform: scale(1.15);
    }

    60% {
        transform: scale(1);
    }
}

.button1 .empty {
    animation: beatingHeart 1.2s infinite;
}
