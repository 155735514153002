* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* outline: 3px solid red !important; */
  font-family: 'Poppins', sans-serif;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



a {
  text-decoration: none;
  color: #353535;

}

.all-btn {
  width: 110px;
  /* text-align: center; */
}

.active {


  color: #1976d2;
  background-color: transparent;


}

.toastContainerBox {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  width: 300px;
  z-index: 1000;
  margin:50px auto;
}

.margin {
  margin-top: 120px;
  width: 100%;
  padding: 0;
}

@media screen and (max-width: 960px) {
  .all-btn {
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toastContainerBox {
    /* max-width: 250px; */
    /* top: 50px;
    left: 140px; */

  }

  .margin {
    margin-top: 70px;
  }


}

@media (max-width: 768px) {
  .recharts-responsive-container {
    width: 100%;
  }
}


@media (min-width: 768px) and (max-width:1023px) {}

/* .placeholder-animation {
  position: relative;
}

.placeholder-animation::before {
  content: attr(placeholder);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide 2s ease-in-out infinite;
} */

@keyframes slide {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(-100%);
  }
}